<template>
  <AuthWrapper>
    <div class="auth-cards">
      <cds-cards>
        <a-form
            @finish="handleSubmit"
            :model="formState"
            layout="vertical"
            :rules="rules"
            :validate-trigger="['change', 'blur']"
        >
          <cds-heading as="h3" class="color-primary">Forgot password?</cds-heading>
          <p class="forgot-text">
            Requires your primary register email address
          </p>
          <a-form-item label="Type" name="type">
            <a-radio-group v-model:value="formState.type">
              <a-radio :value="1">Password</a-radio>
              <a-radio :value="2">QrCode</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="Username" name="username">
            <a-input v-model:value="formState.username" placeholder="Username"/>
          </a-form-item>
          <a-form-item class="text-center">
            <cds-button class="btn-reset" html-type="submit" type="primary" size="large">
              {{ isLoading ? "Loading..." : "Reset password" }}
            </cds-button>
          </a-form-item>
          <p class="return-text text-center">Back to
            <router-link to="/auth/login">Login</router-link>
          </p>
        </a-form>
      </cds-cards>
    </div>
  </AuthWrapper>
</template>
<script>
import {computed, defineComponent, reactive} from 'vue';
import {AuthWrapper} from './style';
import {maxlength, required} from "@/util/validation";
import {useStore} from "vuex";

const ForgotPassword = defineComponent({
  name: 'ForgotPassword',
  components: {AuthWrapper},
  setup() {
    const {state, dispatch} = useStore();
    const isLoading = computed(() => state.auth.loading);
    const rules = {
      username: [required('Username'), maxlength(50, 'Username')],
    }

    const formState = reactive({
      email: '',
      username: '',
      type: 1
    });

    const handleSubmit = () => {
      dispatch("forgot", formState);
    };

    return {
      handleSubmit,
      formState,
      rules,
      isLoading
    };
  },
});

export default ForgotPassword;
</script>

<style scoped lang="scss">


.auth-cards {
  width: 400px;
  margin: auto;
  margin-top: 20vh;
}

@media only screen and (max-width: 767px) {
  .auth-cards {
    width: 300px;
  }
}


</style>