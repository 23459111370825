<template>
  <div class="main-login h-full w-full">
    <router-view></router-view>
  </div>
</template>
<script>
import {Aside, Content} from './style';

const AuthLayout = {
  name: 'AuthLayout',
  components: {Aside, Content},
};

export default AuthLayout;
</script>

<style scoped lang="scss">


</style>