<template>
  <div
    class="auth-cards min-h-[100vh] h-full w-full rounded-0 relative overflow-y-auto !py-5"
  >
    <div class="h-full right-side pt-[140px] py-[50px] float-right">
      <div class="w-full px-[47px] login-wrapper">
        <img
          src="@/static/img/auth/hawk-sense-logo.png"
          width="225"
          :height="11"
          alt="hawk-sense-logo"
          class="mb-[35px] mx-auto"
        />
        <div class="form-login relative">
          <div class="px-[27px] pt-[51px] w-full">
            <a-form @finish="handleSubmit" :model="formState" layout="vertical">
              <a-form-item name="username">
                <span class="text-sm text-ems-gray300 mb-1">{{
                  t('sign-in.username')
                }}</span>
                <a-input
                  type="text"
                  v-model:value="formState.username"
                  :placeholder="t('sign-in.username')"
                  :class="
                    error ? '!border-1 !border-solid !border-ems-main1' : ''
                  "
                />
                <span
                  class="text-[12px] text-ems-main2 mt-1 block"
                  v-if="error"
                  >{{ t('sign-in.input-again') }}</span
                >
              </a-form-item>
              <a-form-item name="password">
                <span class="text-sm text-ems-gray300 mb-1">{{
                  t('sign-in.password')
                }}</span>
                <a-input-password
                  type="password"
                  v-model:value="formState.password"
                  :placeholder="t('sign-in.password')"
                  :class="
                    error ? '!border-1 !border-solid !border-ems-main1' : ''
                  "
                />
                <span
                  class="text-[12px] text-ems-main2 mt-1 block"
                  v-if="error"
                  >{{ t('sign-in.input-again') }}</span
                >
              </a-form-item>
              <!-- <div
                class="grid grid-cols-1 md:grid-cols-2 gap-2 text-ems-gray500 text-normal text-sm"
              >
                <span class="cursor-pointer">{{
                  t('sign-in.login-by-sso')
                }}</span>
                <span class="cursor-pointer md:justify-self-end">{{
                  t('sign-in.forgot-pass')
                }}</span>
              </div> -->
              <!-- <div class="flex mt-[18px] cursor-pointer">
                <img
                  src="@/static/img/auth/icon-face-id.svg"
                  width="18"
                  height="18"
                  alt="icon-face-id"
                  class="mr-2"
                />
                <span class="text-sm text-ems-main1 font-normal">{{
                  t('sign-in.biometrics')
                }}</span>
              </div> -->
              <a-form-item>
                <a-button
                  html-type="submit"
                  :loading="isLoading"
                  class="py-3 mt-4 bg-cover btn-login w-[165px] text-base text-ems-white font-medium float-right mr-[-26px] bg-transparent"
                >
                  {{ t('sign-in.login') }}
                </a-button>
              </a-form-item>
            </a-form>
          </div>
        </div>

        <span class="mt-[34px] text-sm text-ems-main2 block text-center">
          {{ error }}
        </span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const { state, dispatch } = useStore();
const isLoading = computed(() => state.auth.loading);
const error = computed(() => state.auth.error);
const handleSubmit = () => {
  dispatch('login', formState);
};
const formState = reactive({
  username: '',
  password: '',
});
</script>

<style lang="scss">
.auth-cards {
  background-image: url('../../static/img/auth/login-bg.png');
  background-size: cover;
  background-repeat: round;
}
.auth-cards .right-side {
  background-image: url('../../static/img/auth/bg-form-login.png');
  background-size: cover;
  background-repeat: round;
}
.auth-cards .right-side .login-wrapper .form-login {
  background-image: url('../../static/img/auth/form-bg.png');
  background-size: auto;
  background-repeat: round;
}
.auth-cards .right-side .login-wrapper .form-login .btn-login {
  transition: all 0.3s ease-in-out;
  background: url('../../static/img/auth/bg-btn-login.png') round;
  border-bottom-right-radius: 8px;
  border: none;
  height: fit-content;
}
.auth-cards .right-side .login-wrapper .form-login .btn-login:hover {
  border: none !important;
  background: url('../../static/img/auth/bg-btn-login-hover.png')
                round;
  color: white;
  font-size: 16px;
  font-weight: 700;
}
.auth-cards .right-side .login-wrapper .form-login .ant-form .ant-input, .auth-cards .right-side .login-wrapper .form-login .ant-form .ant-input-password {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: none;
  border-bottom: 1px solid transparent;
}
.auth-cards .right-side .login-wrapper .form-login .ant-form .ant-input:hover, .auth-cards .right-side .login-wrapper .form-login .ant-form .ant-input-password:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 67, 67, var(--tw-bg-opacity));
  border-bottom: 1px solid white !important;
}
.auth-cards .right-side .login-wrapper .form-login .ant-form .ant-input {
  padding: 8px 11px;
  --tw-text-opacity: 1;
  color: rgba(123, 123, 123, var(--tw-text-opacity));
}
.auth-cards .right-side .login-wrapper .form-login .ant-form .ant-input-password {
  padding: 7px 11px;
}
.auth-cards .right-side .login-wrapper .form-login .ant-form .ant-input-password:hover {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}
.auth-cards .right-side .login-wrapper .form-login .ant-form .ant-input-password #form_item_password {
  height: 23px;
  background-color: transparent;
  border: none !important;
  padding: 0 !important;
}
.auth-cards .ant-card-body {
  height: 100%;
  width: 100%;
}
</style>
