<template>
  <AuthWrapper>
    <div class="auth-cards">
      <cds-cards>
        <a-form
            @finish="handleSubmit"
            :model="formState"
            layout="vertical"
            :rules="rules"
            :validate-trigger="['change', 'blur']"
        >
          <cds-heading as="h3" class="color-primary">Reset password for {{ user }}</cds-heading>
          <a-form-item label="Password" name="password">
            <a-input
                type="password"
                v-model:value="formState.password"
                placeholder="Password"
            />
          </a-form-item>
          <a-form-item label="Confirm pasword" name="rePassword">
            <a-input
                type="password"
                v-model:value="formState.rePassword"
                placeholder="Password"
            />
          </a-form-item>
          <a-form-item class="text-center">
            <cds-button class="btn-reset" html-type="submit" type="primary" size="large">
              {{ isLoading ? "Loading..." : "Reset password" }}
            </cds-button>
          </a-form-item>
          <p class="return-text text-center">Back to
            <router-link to="/auth/login">Login</router-link>
          </p>
        </a-form>
      </cds-cards>
    </div>
  </AuthWrapper>
</template>
<script>
import {computed, defineComponent, reactive} from 'vue';
import {AuthWrapper} from './style';
import {confirmPassword, maxlength, required} from "@/util/validation";
import {useStore} from "vuex";
import {useRoute} from 'vue-router'

const ForgotPassword = defineComponent({
  name: 'ForgotPassword',
  components: {AuthWrapper},
  setup() {
    const route = useRoute()
    const {state, dispatch} = useStore();
    const isLoading = computed(() => state.auth.loading);

    const formState = reactive({
      password: '',
      rePassword: '',
    });

    const rules = {
      password: [required('Mật khẩu'), maxlength(50, 'Mật khẩu')],
      rePassword: [required('Nhập lại mật khẩu'), maxlength(50, 'Nhập lại mật khẩu'), confirmPassword(formState)],
    }

    const token = atob(route.query.token) + ''
    const user = token.substring(0, token.indexOf(':'))

    const handleSubmit = () => {
      dispatch("resetPassword", {...formState, token: route.query.token});
    };

    return {
      handleSubmit,
      formState,
      rules,
      user,
      isLoading
    };
  },
});

export default ForgotPassword;
</script>

<style scoped lang="scss">


.auth-cards {
  width: 400px;
  margin: auto;
  margin-top: 20vh;
}

@media only screen and (max-width: 767px) {
  .auth-cards {
    width: 300px;
  }
}


</style>
